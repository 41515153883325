/*
  Overrides MaterialTable container style in
  @/components/Settings/Workspace/Holidays/HolidaysTable.
*/
.HolidaysTable {
  height: 100%;
}

.HolidaysTable .MuiPaper-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  border-radius: 5px;
}

/*
  This child selector overrides Component-horizontalScrollContainer,
  which changes id number every mount.
*/
.HolidaysTable .MuiPaper-root > div:nth-child(2),
.HolidaysTable .MuiPaper-root > div:nth-child(2) > div,
.HolidaysTable .MuiPaper-root > div:nth-child(2) > div > div,
.HolidaysTable .MuiPaper-root > div:nth-child(2) > div > div > div {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.HolidaysTable .MuiPaper-root .MuiTable-root {
  display: flex;
  flex-direction: column;
}

.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableHead-root {
  display: flex;
  flex: 1;
  position: sticky;
  top: 0;
  text-transform: uppercase;
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableHead-root
  .MuiTableCell-head {
  font-weight: 400;
  font-size: 12px;
  padding: 13px;
}

.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableHead-root tr,
.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableHead-root th {
  display: flex;
  flex: 1;
  border-bottom: unset;
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableHead-root
  th
  .MuiTableSortLabel-root
  .MuiTableSortLabel-icon {
  opacity: 1;
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableHead-root
  th:last-child
  .MuiButtonBase-root {
  display: none;
}

.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableBody-root {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  font-size: 14px;
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableBody-root::-webkit-scrollbar {
  display: none !important;
}

.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableBody-root tr,
.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableBody-root td {
  display: flex;
}

.HolidaysTable .MuiPaper-root .MuiTable-root .MuiTableBody-root td {
  flex: 1;
  flex-direction: column;
  padding: 10px 15px;
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableBody-root
  td:last-child
  .MuiIconButton-root:hover {
  background-color: unset;
}

.HolidaysTable
  .MuiPaper-root
  .MuiTable-root
  .MuiTableBody-root
  td:last-child
  div {
  justify-content: flex-end;
}

/*
  Overrides Flatpickr style in
  @/components/Settings/Workspace/BusinessHours/BusinessHoursSchedule/TimeRangePicker.
*/

.flatpickr-input {
  color: #4d4d4d;
  width: 98px;
  border: 1px solid;
  cursor: pointer;
  height: 38px;
  padding: 0;
  font-size: 1rem;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: #fff;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-radius: 5px;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  display: none;
}

.flatpickr-time {
  background-color: #ffffff;
  box-shadow: 0px 2px 3px 1px rgb(0 0 0 / 16%);
  border-radius: 5px;
  border: 1px solid #d6d6d6;
}

.flatpickr-time input {
  font-size: 1.15em;
}

.flatpickr-time-separator {
  font-size: 1.15em;
}

.numInputWrapper input:active:focus:hover {
  background-color: #dfe3e8;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 6px solid #727272;
}

.numInputWrapper span.arrowDown:after {
  border-top: 6px solid #727272;
}

:root {
  font-size: 13px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #f0f3f7;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #9a9a9a transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  border: none;
  margin: 0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.15;
  margin: 0;
}

div[tabindex="-1"]:focus,
div[tabindex="0"]:focus {
  outline: 0;
}

[data-count][data-badge]::after {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

[data-badge]::after,
[data-badge-feedback]::after,
[data-badge-changelog]::after {
  color: #fff !important;
  height: 20px !important;
  padding: unset !important;
  font-size: 0.85em !important;
  min-width: 20px !important;
  line-height: 15px !important;
  border-radius: 50% !important;
  background-color: #ed553b !important;
  top: unset !important;
  right: 2px !important;
  bottom: 0px !important;
}

button {
  background-color: unset;
  border: unset;
  padding: unset;
  margin: unset;
  cursor: pointer;
  font-size: inherit;
}

figure {
  margin: unset;
}
